<template>
  <div id="page-branches-list">
    <k-crud-layout :search.sync="searchQuery">
      <template #header>
        {{ $tc('branch.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          tile
          depressed
          @click="createDialog = true"
        >
          {{ $t('actions.createResource', { resource: $tc('branch.title', 1) }) }}
        </v-btn>
        <BranchForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('branch.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <KCrudTable
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="branch.fields"
          resource="branch"
          @click:edit="openUpdate"
        />
      </template>
    </k-crud-layout>
    <BranchForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('branch.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Branch from '@/application/models/Branch.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/branch/api/branch.js';
import BranchForm from '@/modules/branch/components/BranchForm.vue';

export default {
  name: 'BranchIndex',
  components: {
    KCrudTable,
    BranchForm,
    KCrudLayout,
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Branch(),
      createFormValues: new Branch(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'sectorName',
          language: 'sectorName',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'settings.branches' },
        text: this.$tc('branch.title', 2),
      },
    ]);
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.createFormValues);
    },
    indexRequest() {
      return index(...arguments);
    },
    async openUpdate(item) {
      this.updateFormValues = new Branch();
      const branchShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(branchShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>

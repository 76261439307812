<template>
  <k-field-group language-prefix="position.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <KTextField
          v-model="values.name"
          field="name"
          grid="col-sm-4 pr-2"
          required
        />
        <SectorAutocomplete
          v-model="values.sector"
          field="sectorId"
          grid="col-sm-4 pr-2"
          required
        />
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import SectorAutocomplete from '@/modules/sectors/components/SectorAutocomplete.vue';

export default {
  name: 'BranchForm',
  components: {
    SectorAutocomplete,
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
      ];
    },
  },
};
</script>

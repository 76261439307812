import Model from '@/application/models/model.js';

class Branch extends Model {
  id = 0;
  name = '';
  sector = null;

  mapForRequest() {
    return {
      name: this.name,
      sectorId: this.sector?.id,
    };
  }
}

export default Branch;
